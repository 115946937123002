import store from '@/store'
import { ref } from '@vue/composition-api'

export default function useDailyMealsList() {
  const dailyMealsListTable = ref([])

  const scheduledTableColumns = [
    { text: 'name (ar)', value: 'item_name_ar' },
    { text: 'name (en)', value: 'item_name_en' },
    { text: 'Chef', value: 'chief_name' },
    { text: 'Kitchen', value: 'kitchen_name' },
    { text: 'Price (JD)', value: 'price' },
    { text: 'Status', value: 'status' },
    { text: 'Rate', value: 'rate' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const scheduledTableColumnsHestory = [
    { text: 'name (ar)', value: 'item_name_ar' },
    { text: 'name (en)', value: 'item_name_en' },
    { text: 'Chef', value: 'chief_name' },
    { text: 'Kitchen', value: 'kitchen_name' },
    { text: 'Price (JD)', value: 'price' },
    { text: 'Status', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalDailyMealsListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const dailyMealsTotalLocal = ref([])
  const scheduledSelectedRows = ref([])

  // fetch data
  const fetchDailyMeals = date => {
    options.value.date = date
    store
      .dispatch('app-inventory/fetchDailyMeals', options.value)
      .then(response => {
        const { filteredData, total } = response?.data?.data
        dailyMealsListTable.value = filteredData.filter(ele => ele.category.used_in_all_meals == 0)
        totalDailyMealsListTable.value = total
        dailyMealsTotalLocal.value = total
        console.log('SelectedDailyMeals', dailyMealsListTable.value)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchDailyMealsHistory = date => {
    options.value.date = date
    store
      .dispatch('app-inventory/fetchDailyMealsHistory', options.value)
      .then(response => {
        const { filteredData, total } = response?.data?.data
        dailyMealsListTable.value = filteredData
        totalDailyMealsListTable.value = total
        dailyMealsTotalLocal.value = total
        console.log('SelectedDailyMeals', dailyMealsListTable.value)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  return {
    scheduledTableColumnsHestory,
    dailyMealsListTable,
    scheduledTableColumns,
    searchQuery,
    totalDailyMealsListTable,
    loading,
    options,
    dailyMealsTotalLocal,
    scheduledSelectedRows,
    fetchDailyMeals,
    fetchDailyMealsHistory,
  }
}
