import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useItemsList() {
  const itemsListTable = ref([])

  const tableColumns = [
    { text: 'Name (en)', value: 'name_en' },
    { text: 'Name (ar)', value: 'name_ar' },
    { text: 'Chef', value: 'chief_name' },
    { text: 'Kitchen', value: 'kitchen_name' },
    { text: 'Size', value: 'sub_items' },
    { text: 'Price (JOD)', value: 'price' },
    { text: 'Rate', value: 'rate' },
    { text: 'Total Quantity', value: 'quantity' },
  ]

  const tableColumnsForSizes = [
    { text: 'Name', value: 'sub_item_name', sortable: false },
    { text: 'Quantity', value: 'quantity', sortable: false },
    { text: 'Sold', value: 'sold', sortable: false },
    { text: 'Remaining Quantity', value: 'net_quantity', sortable: false },
    { text: 'Status', value: 'status', sortable: false },
    { text: 'price in (JOD)', value: 'price', sortable: false },
    { text: 'price in (point)', value: 'net_points', sortable: false },
    { text: 'Get From Others', value: 'get_from_others', sortable: false },
    { text: 'Is Default', value: 'is_default', sortable: false },
    { text: 'Image', value: 'image', sortable: false , align:'center'},
  ]

  const searchQuery = ref('')
  const planFilter = ref(null)
  const subcategory_id = ref(null)
  const totalItemsListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const itemsTotalLocal = ref([])
  const selectedRows = ref([])
  const selectedDate = ref('')

  // fetch data
  const fetchItems = (date,type) => {
    console.log('date', date)
    if (!date) {
      loading.value = false
    }
    if (date) {
      options.value.date = date
      options.value.category_id = 1
      if (subcategory_id.value) {
        options.value.subcategory_id = subcategory_id.value
      }else{
        options.value.subcategory_id = null
      }
      options.value.q = searchQuery.value
      store
        .dispatch('app-inventory/fetchItems', options.value)
        .then(response => {
          const { filteredData, total } = response?.data?.data
          itemsListTable.value = filteredData
          totalItemsListTable.value = total
          itemsTotalLocal.value = total
          console.log('Items', itemsListTable.value)
          // remove loading state
          loading.value = false
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  watch([searchQuery, planFilter, options, subcategory_id], () => {
    console.log('planFilter', planFilter.value)
    console.log('subcategory_id', subcategory_id.value)
    loading.value = true
    selectedRows.value = []
    fetchItems(selectedDate.value)
  })

  return {
    searchQuery,
    tableColumnsForSizes,
    selectedDate,
    itemsListTable,
    planFilter,
    subcategory_id,
    tableColumns,
    totalItemsListTable,
    loading,
    options,
    itemsTotalLocal,
    selectedRows,
    fetchItems,
  }
}
