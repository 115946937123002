<template>
  <v-navigation-drawer
    :value="isEditDailyMealSidebarActive"
    :permanent="isEditDailyMealSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 800 : '100%'"
    app
    @input="val => $emit('update:isEditDailyMealSidebarActive', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Edit selected item </span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:isEditDailyMealSidebarActive', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit(item)">
          <v-text-field
            v-model="dailyMealItemData.item_name_en"
            outlined
            dense
            label="Item"
            hide-details="auto"
            readonly
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-if="dailyMealItemData.chief_name"
            v-model="dailyMealItemData.chief_name"
            outlined
            dense
            label="Chef"
            readonly
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-if="dailyMealItemData.kitchen_name"
            v-model="dailyMealItemData.kitchen_name"
            outlined
            dense
            label="Kitchen"
            readonly
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-if="dailyMealItemData.daily_meals_sub_items"
            v-model="dailyMealItemData.offer"
            outlined
            dense
            label="Offer %"
            placeholder="15"
            :rules="[validators.decimal_integer_Validator]"
            hide-details="auto"
            class="mb-6"
            @keypress="isNumber($event)"
            hide-spin-buttons
            :persistent-hint="dailyMealItemData.offer > 0 && !dailyMealItemData.daily_meals_sub_items.length"
            hint="if you need to reset the offer to 0 you should edit it to 1"
            :disabled="dailyMealItemData.daily_meals_sub_items.length > 0"
          ></v-text-field>

          <v-text-field
            v-if="dailyMealItemData.daily_meals_sub_items"
            v-model="dailyMealItemData.quantity"
            outlined
            dense
            :rules="[validators.integerValidator]"
            label="Quantity"
            placeholder="20"
            hide-details="auto"
            class="mb-6"
            hide-spin-buttons
            :disabled="dailyMealItemData.daily_meals_sub_items.length > 0"
          ></v-text-field>

          <template v-if="dailyMealItemData.daily_meals_sub_items">
            <div v-if="dailyMealItemData.daily_meals_sub_items.length > 0">
              <v-data-table
                :headers="sizesHeaders"
                :items="dailyMealItemData.daily_meals_sub_items"
                :hide-default-footer="true"
                class="elevation-1 mb-3"
              >
                <template v-slot:item.quantity="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.quantity"
                    large
                    persistent
                    @save="save(item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    <div>{{ item.quantity }}</div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Quantity</div>
                      <v-text-field v-model="item.quantity" label="Edit" single-line counter autofocus></v-text-field>
                    </template>
                    <template v-slot:item.get_from_others="{ item }">
                      <v-simple-checkbox v-model="item.get_from_others" disabled></v-simple-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.offer="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.offer"
                    large
                    persistent
                    @save="save(item)"
                    @cancel="cancel"
                    @open="open(item, 'offer')"
                    @close="close"
                  >
                    <div>{{ item.offer == 1 ? 0 : item.offer }}</div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Offer</div>
                      <v-text-field
                        v-model="item.offer"
                        label="Edit"
                        prefix="%"
                        :rules="item.offer == 0 ? ['min value 1'] : [validators.integerValidator]"
                        single-line
                        :persistent-hint="item.offer > 0"
                        hint="if you need to reset the offer to 0 you should edit it to 1, min offer 2%"
                        autofocus
                      ></v-text-field>
                    </template>
                    <template v-slot:item.get_from_others="{ item }">
                      <v-simple-checkbox v-model="item.get_from_others" disabled></v-simple-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.is_default="{ item }">
                  <div>{{ item.is_default ? '✔' : '✘' }}</div>
                </template>
                <template v-slot:item.get_from_others="{ item }">
                  <div>{{ item.get_from_others ? '✔' : '✘' }}</div>
                </template>
              </v-data-table>
            </div>
          </template>

          <template v-if="dailyMealItemData.daily_meals_add_ons">
            <div v-if="dailyMealItemData.daily_meals_add_ons.length > 0">
              <v-data-table
                :headers="addonsHeaders"
                :items="dailyMealItemData.daily_meals_add_ons"
                :hide-default-footer="true"
                class="elevation-1 mb-3"
              >
                <!-- <template v-slot:item.actions="{ item }">
                  <v-btn @click="handleClick(item)">
                    <v-icon>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template> -->
              </v-data-table>
            </div>
          </template>
          <v-card-text style="color: #ff3342; white-space: pre-wrap">
            {{ message }}
          </v-card-text>

          <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> Submit </v-btn>
          <v-btn color="secondary" outlined @click="$emit('update:isEditDailyMealSidebarActive', false)">
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import { emailValidator, integerValidator, decimal_integer_Validator, required } from '@core/utils/validation'
import { mdiClose, mdiDelete } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  emits: ['refetchDataDailyMeals'],
  props: {
    isEditDailyMealSidebarActive: {
      type: Boolean,
      required: true,
    },
    dailyMealItem: {
      type: Object,
    },
    selectedDate: {
      type: String,
    },
  },
  methods: {
    save(item) {},
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open(item, type) {
      if (type == 'offer') {
        let target = this.dailyMealItemData.daily_meals_sub_items.find(ele => {
          return ele.id == item.id
        })
        console.log('target.offer', target.offer)
        target.offer == 1 ? (target.offer = 0) : (target.offer = target.offer)
        console.log('target.offer', target.offer)
      }
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close() {},
  },
  setup(props, { emit }) {
    const valid = ref(false)
    const form = ref(null)
    const add_on_id_del_arr = ref([])
    const isLoading = ref(false)
    const message = ref('')

    const validate = () => {
      form.value.validate()
    }

    const dailyMealItemData = ref({
      item_name_en: null,
      chief_name: null,
      daily_meals_add_ons: null,
      daily_meals_sub_items: null,
    })

    const sizesHeaders = [
      { text: 'Size', value: 'sub_item_name_en' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Price (JOD)', value: 'price' },
      { text: 'Offer %', value: 'offer' },
      { text: 'Isdefault', value: 'is_default', sortable: false, align: 'center' },
      { text: 'Get From Others', value: 'get_from_others', sortable: false, align: 'center' },
    ]

    const addonsHeaders = [
      { text: 'Add Ons', value: 'daily_meal_item.name' },
      { text: 'Price (JOD)', value: 'price' },
      { text: 'Quantity', value: 'daily_meal_id' },
    ]

    const isNumber = evt => {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
    const onSubmit = () => {
      if (valid.value) {
        isLoading.value = true
        const newData = {
          daily_meal_item: [
            {
              id: dailyMealItemData.value.id,
              offer: dailyMealItemData.value.offer,
              quantity: dailyMealItemData.value.quantity,
            },
          ],
          daily_meal_sub_items: [],
        }

        if (dailyMealItemData.value.daily_meals_sub_items.length > 0) {
          dailyMealItemData.value.daily_meals_sub_items.forEach(sub => {
            const obj = {
              id: sub.id,
              offer: parseFloat(sub.offer),
              quantity: parseInt(sub.quantity),
              get_from_others: sub.get_from_others,
            }
            newData.daily_meal_sub_items.push(obj)
          })
        }
        console.log('newData', newData)

        store
          .dispatch('app-inventory/updateDailyMealItem', newData)
          .then(response => {
            message.value = ''
            console.log('updateDailyMealItem', response)
            if (add_on_id_del_arr.value.length) {
              store
                .dispatch('app-inventory/deleteDailyMealAddOn', add_on_id_del_arr.value)
                .then(() => {
                  message.value = ''
                  emit('refetchDataDailyMeals')
                  emit('update:isEditDailyMealSidebarActive', false)
                })
                .catch(error => {
                  message.value = error.response?.data?.message
                  console.log(error?.response?.data?.message)
                  console.log(error)
                })
            } else {
              emit('refetchDataDailyMeals')
              emit('update:isEditDailyMealSidebarActive', false)
            }
            isLoading.value = false
          })
          .catch(error => {
            message.value = error.response?.data?.message
            console.log(error.response?.data?.message)
            if (error.response?.data?.message == 'The daily meal item.0.offer must not be greater than 100. ') {
              error.response.data.message = 'offer must not be greater than 100. '
            }
            console.log(error)
            isLoading.value = false
          })
      } else {
        validate()
      }
    }

    const handleClick = item => {
      console.log('item', item)
      add_on_id_del_arr.value.push(item.add_on_id)
      dailyMealItemData.value.daily_meals_add_ons = dailyMealItemData.value.daily_meals_add_ons.filter(
        ele => ele.id !== item.id,
      )

      console.log('add_on_id_del_arr.value', add_on_id_del_arr.value)
    }

    watch(
      () => props.isEditDailyMealSidebarActive,
      (newValue, _oldValue) => {
        console.log('newValue', newValue)
        if (newValue) {
          message.value = ''
          add_on_id_del_arr.value = []
          isLoading.value = true
          console.log('props.selectedDate', props.selectedDate)
          store
            .dispatch('app-inventory/fetchDailyMealItem', {
              id: props.dailyMealItem.item_id,
              date: props.selectedDate,
            })
            .then(response => {
              dailyMealItemData.value = response
              if (dailyMealItemData.value.offer == 1) {
                dailyMealItemData.value.offer = 0
              }
              console.log('dailyMealItemData.value', dailyMealItemData.value)
              isLoading.value = false
            })
            .catch(error => {
              isLoading.value = false

              console.log(error)
            })
        }
      },
    )
    return {
      isNumber,
      message,
      isLoading,
      form,
      dailyMealItemData,
      valid,
      sizesHeaders,
      addonsHeaders,
      onSubmit,
      validate,
      handleClick,

      // validation
      validators: { required, emailValidator, integerValidator, decimal_integer_Validator },
      icons: {
        mdiClose,
        mdiDelete,
      },
    }
  },
}
</script>
