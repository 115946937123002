<template>
  <div id="items-list">
    <inventory-item-edit
      style="z-index: 100"
      :isEditDailyMealSidebarActive.sync="isEditDailyMealSidebarActive"
      :daily-meal-item="dailyMealItem"
      :selectedDate="selectedDate"
      @refetchDataDailyMeals="refetchDataDailyMeals"
    />
    <v-row class="mb-5">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">Choose the Date</h2>
              <v-menu
                v-model="picker"
                :close-on-content-click="false"
                :nudge-right="-20"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedDate"
                    outlined
                    dense
                    readonly
                    @paste.prevent
                    @keypress.prevent
                    label="Date"
                    placeholder="YYYY-MM-DD"
                    hide-details="auto"
                    class="mb-6 required-star"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker :min="now" v-model="selectedDate" no-title>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picker = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="picker = false"> OK </v-btn></v-date-picker
                >
              </v-menu>
              <div v-if="message" class="mb-5" style="color: #ff3342; white-space: pre-wrap">
                {{ message }}
              </div>
              <v-btn color="primary" class="col-12" @click="fetchByDate">
                <span>Confirm</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div id="sizes-list">
      <v-card>
        <v-card-title> Selected Meals </v-card-title>
        <v-divider class="mt-4"></v-divider>

        <v-data-table
          v-model="scheduledSelectedRows"
          :headers="scheduledTableColumns"
          :items="dailyMealsListTable"
          :options.sync="options"
          :server-items-length="totalDailyMealsListTable.length"
          :loading="loading"
        >
          <!-- name EN -->
          <template #[`item.name_en`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.name_en }}
              </div>
            </div>
          </template>

          <!-- rate -->
          <template #[`item.rate`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.rate + '★' ? item.rate : '___' }}
              </div>
            </div>
          </template>

          <!-- status-->
          <template #[`item.status`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.net_quantity ? 'In Stock' : 'Out Stock' }}
              </div>
            </div>
          </template>

          <!-- kitchen_name -->
          <template #[`item.kitchen_name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.kitchen_name ? item.kitchen_name : '___' }}
              </div>
            </div>
          </template>

          <!-- name AR -->
          <template #[`item.name_ar`]="{ item }">
            <div class="d-flex align-center">
              <span class="text-capitalize">{{ item.name_ar }}</span>
            </div>
          </template>

          <template #[`item.chief_name`]="{ item }">
            <div class="d-flex align-center">
              <span class="text-capitalize">{{ item.chief_name ? item.chief_name : '___' }}</span>
            </div>
          </template>

          <!-- actions -->
          <template #[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="saveDate(item.item_id)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span>View</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="handleClick(item)"
                  @click.stop="isEditDailyMealSidebarActive = !isEditDailyMealSidebarActive"
                >
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiFileDocumentOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item @click="handleClickDelete(item)" link>
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <!-- list filters -->
    <v-card class="mt-10">
      <v-card-title> Non-selected Meals {{ subcategory_id }}</v-card-title>
      <v-card-title> Search &amp; Filter </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

      <v-row class="px-2 ma-0">
        <!-- plan filter -->
        <v-col cols="12" sm="4">
          <v-select
            v-model="subcategory_id"
            label="filleter by category"
            placeholder="Select category"
            :items="sub_category"
            item-text="name_en"
            item-value="id"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="items-search  mb-4"
        >
        </v-text-field>
        </v-col>
      </v-row>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click="addToDailyMeals">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Schedule Meals</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="selectedDate.length > 0 ? itemsListTable : []"
        :options.sync="options"
        :server-items-length="totalItemsListTable"
        :loading="loading"
        show-select
      >
        <!-- name EN -->
        <template #[`item.name_en`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{ item.name_en }}
            </div>
          </div>
        </template>

        <template #[`item.kitchen_name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{ item.kitchen_name ? item.kitchen_name : '___' }}
            </div>
          </div>
        </template>

        <!-- name AR -->
        <template #[`item.name_ar`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.name_ar }}</span>
          </div>
        </template>

        <!-- rate -->
        <template #[`item.rate`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{ item.rate + '★' ? item.rate : '___' }}
            </div>
          </div>
        </template>

        <!-- Chef Name -->
        <template #[`item.chief_name`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.chief_name ? item.chief_name : '___' }}</span>
          </div>
        </template>

        <!-- Size -->
        <template #[`item.sub_items`]="{ item }">
          <div v-if="item.sub_items.length" class="mb-3 mt-3">
            <div v-for="size in item.sub_items" class="d-flex align-center">
              <tr v-if="size" class="text-capitalize">
                <td>
                  <tbody>
                    <table class="size-table" colspan="2">
                      <tr>
                        <td>
                          {{ size.name_en }}
                        </td>
                      </tr>
                    </table>
                  </tbody>
                </td>
              </tr>
            </div>
          </div>
          <div v-else>___</div>
        </template>
        <!-- price -->
        <template #[`item.price`]="{ item }">
          <div v-if="item.sub_items.length" class="mb-3 mt-3">
            <div v-for="size in item.sub_items" class="d-flex align-center">
              <tr v-if="size" class="text-capitalize">
                <td>
                  <tbody>
                    <table class="size-table">
                      <tr align="left">
                        <td>{{ size.price }}</td>
                      </tr>
                    </table>
                  </tbody>
                </td>
              </tr>
            </div>
          </div>
          <div v-else>{{ item.price }}</div>
        </template>

        <!-- Total Quantity -->
        <template #[`item.quantity`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.quantity ? item.quantity : '___' }}</span>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiUpload,
} from '@mdi/js'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import moment from 'moment'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import inventoryStoreModule from '../inventoryStoreModule'

import InventoryItemEdit from './InventoryItemEdit'
import useDailyMealsList from './useDailyMealsList'
import useItemsList from './useItemsList'
import router from '@/router'
// sidebar

export default {
  components: {
    InventoryItemEdit,
  },
  setup(props, { emit }) {
    const INVENTORY_APP_STORE_MODULE_NAME = 'app-inventory'

    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      if (localStorage.getItem('inventoryDate')?.length) {
        selectedDate.value = localStorage.getItem('inventoryDate')
        fetchByDate()
        localStorage.removeItem('inventoryDate')
      }
    })

    const picker = ref(false)
    const modal = ref(false)
    const now = ref(moment().format())
    const dailyMealItem = ref(null)
    const sub_category = ref([])
    const message = ref('')

    const isEditDailyMealSidebarActive = ref(false)

    const editItem = ref({})

    const {
      itemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      loading,
      options,
      itemsTotalLocal,
      selectedRows,
      planFilter,
      subcategory_id,
      selectedDate,
      fetchItems,
    } = useItemsList()

    const {
      dailyMealsListTable,
      scheduledTableColumns,
      totalDailyMealsListTable,
      sizesTotalLocal,
      scheduledSelectedRows,
      fetchDailyMeals,
    } = useDailyMealsList()

    const fetchByDate = () => {
      if (selectedDate.value.length > 0) {
        fetchDailyMeals(selectedDate.value)
        fetchItems(selectedDate.value)
      } else {
        Swal.fire('Please Choose a date first to fetch the available items!')
      }
    }

    const saveDate = id => {
      if (selectedDate.value.length > 0) {
        localStorage.setItem('inventoryDate', selectedDate.value)
      }
      router.push({
        name: 'apps-inventory-view',
        params: { id: id },
        query: { date: selectedDate ? selectedDate : '' },
      })
    }

    const refetchDataDailyMeals = () => {
      if (selectedDate.value) {
        fetchDailyMeals(selectedDate.value)
      }
    }

    const addToDailyMeals = () => {
      const itemsIds = []
      selectedRows.value.forEach(item => {
        itemsIds.push(item.id)
      })
      console.log('selectedRows.value', selectedRows.value)
      const data = { date: selectedDate.value, item_id: itemsIds }

      store
        .dispatch('app-inventory/createDailyMeal', data)
        .then(response => {
          fetchDailyMeals(selectedDate.value)
          fetchItems(selectedDate.value)
          selectedRows.value = []
          message.value = ''
        })
        .catch(error => {
          console.log(error.response.data)
          message.value = error.response.data.message
        })
        .finally(() => {
          selectedRows.value = []
        })
    }

    const handleClick = item => {
      dailyMealItem.value = item
    }

    const handleClickDelete = item => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        console.log('item', item)
        if (result.isConfirmed) {
          if (item.category.add_ones) {
            store
              .dispatch('app-inventory/deleteDailyMealAddOn', item.id)
              .then(() => {
                Swal.fire('Deleted!', 'entry has been deleted.', 'success')
                console.log('dailyMealsListTable.value', dailyMealsListTable.value)
                dailyMealsListTable.value = dailyMealsListTable.value.filter(ele => ele.id !== item.id)
              })
              .catch(error => {
                console.error(error)
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.message,
                  footer: 'This item might have items that needs to be deleted first',
                })
              })
          } else {
            store
              .dispatch('app-inventory/deleteDaily', item.id)
              .then(() => {
                Swal.fire('Deleted!', 'entry has been deleted.', 'success')
                dailyMealsListTable.value = dailyMealsListTable.value.filter(ele => ele.id !== item.id)
              })
              .catch(error => {
                console.error(error.response.data.message)
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.message,
                  footer: 'This item might have items that needs to be deleted first',
                })
              })
          }
        }
      })
    }

    store
      .dispatch('app-inventory/fetchSubCategories')
      .then(response => {
        sub_category.value = response.filteredData
        console.log('sub_category.value', sub_category.value)
      })
      .catch(error => {
        console.error(error)
      })

    return {
      message,
      saveDate,
      refetchDataDailyMeals,
      subcategory_id,
      sub_category,
      itemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      loading,
      options,
      now,
      itemsTotalLocal,
      selectedRows,
      planFilter,
      dailyMealsListTable,
      scheduledTableColumns,
      totalDailyMealsListTable,
      sizesTotalLocal,
      scheduledSelectedRows,
      selectedDate,
      picker,
      modal,
      isEditDailyMealSidebarActive,
      editItem,
      avatarText,
      dailyMealItem,
      fetchDailyMeals,
      fetchItems,
      fetchByDate,
      addToDailyMeals,
      handleClick,
      handleClickDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
        mdiUpload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';
.size-table {
  tr {
    td,
    th {
      white-space: nowrap;
    }
  }
}
</style>
