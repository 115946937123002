import axios from '@axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    itemToEdit: {},
  },
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, options) {
      if (!options.date) return
      return new Promise((resolve, reject) => {
        axios
          .get('/items', { params: { options } })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSubCategories(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/categories?options={"parent_id":"1"}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchDailyMeals(ctx, options) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/daily-meals`, { params: { options } })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchDailyMealsHistory(ctx, options) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/daily-meals-history`, { params: { options } })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchDailyMealItem(ctx, params) {
      console.log('params',params);
      let URL
      if (params?.type == 'History') {
        URL = `daily-meals/view/${params.id}`
      }else{
        URL = `daily-meals/view/${params.id}/${params.date}`
      }
      return new Promise((resolve, reject) => {
        axios
          .get(URL)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    deleteDailyMealAddOn(ctx, id) {
      let data

      console.log('_.isArray( id )', _.isArray(id))
      if (_.isArray(id)) {
        data = { add_ons_ids: id }
      } else {
        data = { add_ons_ids: [id] }
      }
      const bodyFormData = new FormData()
      bodyFormData.append('add_ons_ids',data.add_ons_ids)
      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/daily-meals/delete-add-ons',
          data,
        })
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    createDailyMeal(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/daily-meals/create', data)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    updateDailyMealItem(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/daily-meals/update', item)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    deleteDaily(ctx, id) {
      const bodyFormData = new FormData()
      bodyFormData.append('id', id)

      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')

      return new Promise((resolve, reject) => {
        axios
          .post('/daily-meals/delete', bodyFormData)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
